import { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import toggler from "../../assets/images/toggler.png";
import close from "../../assets/images/close.png";
import { Link } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import { HashLink } from "react-router-hash-link";
import { NavHashLink } from "react-router-hash-link";

const Header = () => {
  const [togglerActive, setTogglerActive] = useState(false);

  return (
    <Reveal>
      <div style={{ backgroundColor: "white" }}>
        <header className="header">
          <div className="header__mobile">
            <Link to="/">
              <img className="header__logo" src={logo} alt="logo" />
            </Link>
            {togglerActive ? (
              <img
                onClick={() => setTogglerActive(!togglerActive)}
                className="header__icon"
                src={close}
                alt="close"
              />
            ) : (
              <img
                onClick={() => setTogglerActive(!togglerActive)}
                className="header__icon"
                src={toggler}
                alt="toggler"
              />
            )}
          </div>
          <ul
            className={`header__list ${
              togglerActive ? `header__list--opened` : ""
            } `}
          >
            <li
              className={`header__item ${
                togglerActive ? `header__item--opened` : ""
              } `}
            >
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "header__link header__link--active"
                    : "header__link"
                }
              >
                Home
              </NavLink>
            </li>
            <li
              className={`header__item ${
                togglerActive ? `header__item--opened` : ""
              } `}
            >
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive
                    ? "header__link header__link--active"
                    : "header__link"
                }
              >
                About
              </NavLink>
            </li>
            <li
              className={`header__item ${
                togglerActive ? `header__item--opened` : ""
              } `}
            >
              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  isActive
                    ? "header__link header__link--active"
                    : "header__link"
                }
              >
                Contact
              </NavLink>
            </li>
            <li
              className={`header__item ${
                togglerActive ? `header__item--opened` : ""
              } `}
            >
              <NavHashLink
                smooth
                to="#features"
                className="header__link"
                activeClassName="header__link header__link--active"

                // className={({ isActive }) =>
                //   isActive
                //     ? "header__link header__link--active"
                //     : "header__link"
                // }
              >
                Services
              </NavHashLink>
            </li>
          </ul>
        </header>
      </div>
    </Reveal>
  );
};

export default Header;
