import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Particles from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import { Helmet } from "react-helmet";
import { contact } from "../lib/api";
import { useMutation } from "@tanstack/react-query";
import { ErrorMessage } from "@hookform/error-message";

const ContactPage = ({ title }) => {
  const [submitError, setSubmitError] = useState();
  const [submitSuccess, setSubmitSuccess] = useState("");
  let variable = 0;
  const particlesInit = useCallback(async (engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  const { mutate, isSuccess } = useMutation(contact, {
    onSuccess(d, v, c) {
      //  setSubmitError(d?.errorDetails || "");
      //  if (!d?.errorDetails) {
      //    setRegisterSuccess(true);
      //  }
      if (d?.response?.data?.errors) {
        setSubmitError(d?.response?.data?.errors);
      } else {
        setSubmitError();
        setSubmitSuccess("Your message was sent successfully.");
        reset({
          FromName: "",
          FromEmail: "",
          Body: "",
          Subject: "",
        });
      }
    },
    onError(error) {
      setSubmitError(error.response.data.errors);
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      FromName: "",
      FromEmail: "",
      Subject: "",
      Body: "",
    },
  });

  const onSubmit = async (data) => {
    // mutate(data);
    mutate(data);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} data-react-helmet="true" />
      </Helmet>
      {window.innerWidth >= 768 && (
        <Particles
          className="hero__particles"
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: "#fffff",
              },
            },
            fpsLimit: 120,
            preset: "bubbles",
            particles: {
              color: {
                value: "#4981da",
              },
              move: {
                directions: "top",
                enable: true,
                random: true,
                speed: 2,
                straight: true,
                out_mode: "out",
              },
              number: {
                value: 3,
                density: {
                  enable: true,
                  area: 631,
                },
              },
              opacity: {
                value: 0.45,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: 20,
              },
            },
            detectRetina: true,
          }}
        />
      )}
      <div className="containerContact">
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} data-react-helmet="true" />
        </Helmet>
        <h1 className="containerContact__heading">Contact us</h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="containerContact__form"
        >
          {submitSuccess && (
            <p className="containerContact__successText">{submitSuccess}</p>
          )}
          <Controller
            name="FromName"
            control={control}
            rules={{
              required: "Name is required",
            }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <input
                className="containerContact__input"
                value={value}
                onChange={onChange}
                placeholder="Name"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="FromName"
            render={({ message }) => (
              <p className="containerContact__errorText">{message}</p>
            )}
          />

          {submitError?.FromName &&
            submitError?.FromName?.map((error) => (
              <p className="containerContact__errorText">{error}</p>
            ))}
          <Controller
            name="FromEmail"
            control={control}
            rules={{
              required: "Email is required",
            }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <input
                className="containerContact__input"
                value={value}
                onChange={onChange}
                placeholder="Email"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="FromEmail"
            render={({ message }) => (
              <p className="containerContact__errorText">{message}</p>
            )}
          />
          {submitError?.FromEmail &&
            submitError?.FromEmail?.map((error) => (
              <p className="containerContact__errorText">{error}</p>
            ))}
          <Controller
            name="Subject"
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <input
                className="containerContact__input"
                value={value}
                onChange={onChange}
                placeholder="Subject"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="Subject"
            render={({ message }) => (
              <p className="containerContact__errorText">{message}</p>
            )}
          />
          {submitError?.Subject &&
            submitError?.Subject?.map((error) => (
              <p className="containerContact__errorText">{error}</p>
            ))}
          <Controller
            name="Body"
            control={control}
            rules={{
              required: "Body is required",
            }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <textarea
                className="containerContact__input"
                rows="5"
                placeholder="Body"
                value={value}
                onChange={onChange}
              ></textarea>
            )}
          />
          <ErrorMessage
            errors={errors}
            name="Body"
            render={({ message }) => (
              <p className="containerContact__errorText">{message}</p>
            )}
          />
          {submitError?.Body &&
            submitError?.Body?.map((error) => (
              <p className="containerContact__errorText">{error}</p>
            ))}
          <button className="containerContact__button" type="submit">
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default ContactPage;
