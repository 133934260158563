import Slide from "react-reveal/Slide";
const Service = ({ icon, title, paragraph }) => {
    return (
        <Slide left>
            <div className="features__service">
                <div className="features__icon">
                    <img src={icon} alt="service-icon" />
                </div>
                <div>
                    <h3 className="features__service-title">{title}</h3>
                    <p className="features__service-paragraph">{paragraph}</p>
                </div>
            </div>
        </Slide>
    );
};

export default Service;
