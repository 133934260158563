import Particles from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";

const HeroSection = () => {
    const particlesInit = useCallback(async (engine) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {}, []);

    return (
        <div className="hero-about">
            {window.innerWidth >= 768 && (
                <Particles
                    className="hero__particles"
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        background: {
                            color: {
                                value: "#fffff",
                            },
                        },
                        fpsLimit: 120,
                        preset: "bubbles",
                        particles: {
                            color: {
                                value: "#4981da",
                            },
                            move: {
                                directions: "top",
                                enable: true,
                                random: true,
                                speed: 2,
                                straight: true,
                                out_mode: "out",
                            },
                            number: {
                                value: 3,
                                density: {
                                    enable: true,
                                    area: 631,
                                },
                            },
                            opacity: {
                                value: 0.45,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                value: 20,
                            },
                        },
                        detectRetina: true,
                    }}
                />
            )}
            <div className="hero-about__content">
                <div>
                    <h1 className="hero-about__title">
                        About{" "}
                        <span className="hero-about__title hero-about__title--blue">
                            Us
                        </span>
                    </h1>
                    <h2 className="hero-about__main-title">
                        We Are Algodeva{" "}
                        <span className="hero-about__main-title hero-about__main-title--blue">
                            Software
                        </span>{" "}
                        House
                    </h2>
                    <p className="hero-about__paragraph">
                        ALGODEVA is a software solutions provider working on
                        different stacks to guarantee high quality services and
                        massive coverage for the market needs.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
