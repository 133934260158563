import HeroSection from "../components/AboutPage/HeroSection/HeroSection";
import Contact from "../components/Shared/Contact/ContactSection";
import WorkflowSection from "../components/Shared/Workflow/WorkflowSection";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
const AboutPage = ({ title }) => {
    useEffect(() => {
        document.title = "Algodeva - About us";
    }, []);
    return (
        <>
            <HeroSection />
            <WorkflowSection />
            <Contact />
        </>
    );
};

export default AboutPage;
