import Slide from "react-reveal/Slide";
import { ErrorMessage } from "@hookform/error-message";
import { subscribe } from "../../../lib/api";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";

const Contact = () => {
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");

  const { mutate, isSuccess } = useMutation(subscribe, {
    onSuccess(d, v, c) {
      if (d?.isSuccess) {
        setSubmitError("");
        setSubmitSuccess("Email subscribed successfully.");
      } else {
        setSubmitSuccess("");
        console.log(d);
        setSubmitError(d?.errorMessages);
        console.log(submitError);
      }
      console.log(d);
    },
    onError(error) {
      setSubmitSuccess("");
      setSubmitError(
        error.response.data.errorMessages || error.response.data.errors.Email
      );
      console.log(error.response.data.errors.Email);
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Email: "",
    },
  });

  const onSubmit = async (data) => {
    mutate(data);
  };

  return (
    <div className="contact">
      <Slide left>
        <h3 className="contact__title">
          Stay Up To Date With Our News,<span>Ideas And Updates</span>
        </h3>
      </Slide>
      <Slide right>
        <form className="contact__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="contact__inputColumn">
            <Controller
              name="Email"
              control={control}
              rules={{
                required: "Email is required",
              }}
              render={({ field: { onChange, value } }) => (
                <input
                  className="contact__input"
                  placeholder="Email Address"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {
              <ErrorMessage
                errors={errors}
                name="Email"
                render={({ message }) => (
                  <p className="contact__errorText">{message}</p>
                )}
              />
            }
            {submitError &&
              submitError.map((error) => (
                <p className="contact__errorText">{error}</p>
              ))}
            {}
            {submitSuccess && (
              <p className="contact__successText">{submitSuccess}</p>
            )}
          </div>
          <button
            className={`${
              errors?.Email || submitError || submitSuccess
                ? `contact__button`
                : `contact__buttonNoMargin`
            } `}
            type="submit"
          >
            Subscribe
          </button>
        </form>
      </Slide>
    </div>
  );
};

export default Contact;
