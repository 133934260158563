import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
const TermsPage = ({ title }) => {
    useEffect(() => {
        document.title = "Algodeva - Terms of service";
    }, []);
    return (
        <div className="container">
            <h1 className="container__heading">TERMS OF SERVICE AGREEMENT</h1>
            <p className="container__paragraph">
                PLEASE READ THE FOLLOWING TERMS OF SERVICE AGREEMENT CAREFULLY.
                BY ACCESSING OR USING OUR SITES AND OUR SERVICES, YOU HEREBY
                AGREE TO BE BOUND BY THE TERMS AND ALL TERMS INCORPORATED HEREIN
                BY REFERENCE. IT IS THE RESPONSIBILITY OF YOU, THE USER,
                CUSTOMER, OR PROSPECTIVE CUSTOMER TO READ THE TERMS AND
                CONDITIONS BEFORE PROCEEDING TO USE THIS SITE. IF YOU DO NOT
                EXPRESSLY AGREE TO ALL OF THE TERMS AND CONDITIONS, THEN PLEASE
                DO NOT ACCESS OR USE OUR SITES OR OUR SERVICES. THIS TERMS OF
                SERVICE AGREEMENT IS EFFECTIVE AS OF 01/10/2022.
            </p>
            <h2 className="container__title">ACCEPTANCE OF TERMS</h2>
            <p className="container__paragraph">
                The following Terms of Service Agreement (the "TOS") is a
                legally binding agreement that shall govern the relationship
                with our users and others which may interact or interface with
                ALGODEVA TECHNOLOGIES, LLC. and affiliates, in association with
                the use of the website, which includes granit, (the "Site") and
                its Services, which shall be defined below.
            </p>
            <h2 className="container__title">
                DESCRIPTION OF WEBSITE SERVICES OFFERED <br /> <br />
                The Site is business which has the following description:
            </h2>
            <p className="container__paragraph">
                Business and software consulting
            </p>
            <p className="container__paragraph">
                Any and all visitors to our site shall be deemed as "users" of
                the herein contained Services provided for the purpose of this
                TOS.
            </p>
            <p className="container__paragraph">
                The user acknowledges and agrees that the Services provided and
                made available through our website and applications, which may
                include some mobile applications and that those applications may
                be made available on various social media networking sites and
                numerous other platforms and downloadable programs, are the sole
                property of ALGODEVA TECHNOLOGIES, LLC. . At its discretion,
                ALGODEVA TECHNOLOGIES, LLC. may offer additional website
                Services and/or products, or update, modify or revise any
                current content and Services, and this Agreement shall apply to
                any and all additional Services and/or products and any and all
                updated, modified or revised Services unless otherwise
                stipulated. ALGODEVA TECHNOLOGIES, LLC. does hereby reserve the
                right to cancel and cease offering any of the aforementioned
                Services and/or products. You, as the end user acknowledge,
                accept and agree that ALGODEVA TECHNOLOGIES, LLC. shall not be
                held liable for any such updates, modifications, revisions,
                suspensions or discontinuance of any of our Services and/or
                products. Your continued use of the Services provided, after
                such posting of any updates, changes, and/or modifications shall
                constitute your acceptance of such updates, changes and/or
                modifications, and as such, frequent review of this Agreement
                and any and all applicable terms and policies should be made by
                you to ensure you are aware of all terms and policies currently
                in effect. Should you not agree to the updated, revised or
                modified terms, you must stop using the provided Services
                forthwith.
            </p>
            <p className="container__paragraph">
                Furthermore, the user understands, acknowledges and agrees that
                the Services offered shall be provided "AS IS" and as such
                ALGODEVA TECHNOLOGIES, LLC. shall not assume any responsibility
                or obligation for the timeliness, missed delivery, deletion
                and/or any failure to store user content, communication or
                personalization settings.
            </p>

            <h2 className="container__title">PRIVACY POLICY</h2>

            <p className="container__paragraph">
                Every member's registration data and various other personal
                information are strictly protected by the ALGODEVA TECHNOLOGIES,
                LLC. Online Privacy Policy. As a member, you herein consent to
                the collection and use of the information provided, including
                the transfer of information within the United States and/or
                other countries for storage, processing or use by ALGODEVA
                TECHNOLOGIES, LLC. and/or our subsidiaries and affiliates.
                CONTENT PLACED OR MADE AVAILABLE FOR COMPANY SERVICES
            </p>
            <p className="container__paragraph">
                ALGODEVA TECHNOLOGIES, LLC. shall not lay claim to ownership of
                any content submitted by any visitor or user, nor make such
                content available for inclusion on our website Services.
                Therefore, you hereby grant and allow for ALGODEVA TECHNOLOGIES,
                LLC. the below listed worldwide, royalty-free and non-exclusive
                licenses, as applicable:
            </p>
            <p className="container__paragraph">
                The content submitted or made available for inclusion on the
                publicly accessible areas of ALGODEVA TECHNOLOGIES, LLC. 's
                sites, the license provided to permit to use, distribute,
                reproduce, modify, adapt, publicly perform and/or publicly
                display said Content on our network Services is for the sole
                purpose of providing and promoting the specific area to which
                this content was placed and/or made available for viewing. This
                license shall be available so long as you are a member of
                ALGODEVA TECHNOLOGIES, LLC. 's sites, and shall terminate at
                such time when you elect to discontinue your membership.
            </p>
            <p className="container__paragraph">
                Photos, audio, video and/or graphics submitted or made available
                for inclusion on the publicly accessible areas of ALGODEVA
                TECHNOLOGIES, LLC. 's sites, the license provided to permit to
                use, distribute, reproduce, modify, adapt, publicly perform
                and/or publicly display said Content on our network Services are
                for the sole purpose of providing and promoting the specific
                area in which this content was placed and/or made available for
                viewing. This license shall be available so long as you are a
                member of ALGODEVA TECHNOLOGIES, LLC. 's sites and shall
                terminate at such time when you elect to discontinue your
                membership
            </p>
            <p className="container__paragraph">
                For any other content submitted or made available for inclusion
                on the publicly accessible areas of ALGODEVA TECHNOLOGIES, LLC.
                's sites, the continuous, binding and completely sub-licensable
                license which is meant to permit to use, distribute, reproduce,
                modify, adapt, publish, translate, publicly perform and/or
                publicly display said content, whether in whole or in part, and
                the incorporation of any such Content into other works in any
                arrangement or medium current used or later developed. Those
                areas which may be deemed "publicly accessible" areas of
                ALGODEVA TECHNOLOGIES, LLC. 's sites are those such areas of our
                network properties which are meant to be available to the
                general public, and which would include message boards and
                groups that are openly available to users.
            </p>

            <h2 className="container__title">COMMERCIAL REUSE OF SERVICES</h2>

            <p className="container__paragraph">
                The user herein agrees not to replicate, duplicate, copy, trade,
                sell, resell nor exploit for any commercial reason any part, use
                of, or access to 's sites.
            </p>

            <h2 className="container__title">MODIFICATIONS</h2>

            <p className="container__paragraph">
                ALGODEVA TECHNOLOGIES, LLC. shall reserve the right at any time
                it may deem fit, to modify, alter and or discontinue, whether
                temporarily or permanently, our service, or any part thereof,
                with or without prior notice. In addition, we shall not be held
                liable to you or to any third party for any such alteration,
                modification, suspension and/or discontinuance of our Services,
                or any part thereof.
            </p>

            <h2 className="container__title">ADVERTISERS</h2>

            <p className="container__paragraph">
                Any correspondence or business dealings with, or the
                participation in any promotions of, advertisers located on or
                through our Services, which may include the payment and/or
                delivery of such related goods and/or Services, and any such
                other term, condition, warranty and/or representation associated
                with such dealings, are and shall be solely between you and any
                such advertiser. Moreover, you herein agree that ALGODEVA
                TECHNOLOGIES, LLC. shall not be held responsible or liable for
                any loss or damage of any nature or manner incurred as a direct
                result of any such dealings or as a result of the presence of
                such advertisers on our website.
            </p>

            <h2 className="container__title">LINKS</h2>

            <p className="container__paragraph">
                Either ALGODEVA TECHNOLOGIES, LLC. or any third parties may
                provide links to other websites and/or resources. Thus, you
                acknowledge and agree that we are not responsible for the
                availability of any such external sites or resources, and as
                such, we do not endorse nor are we responsible or liable for any
                content, products, advertising or any other materials, on or
                available from such third party sites or resources. Furthermore,
                you acknowledge and agree that ALGODEVA TECHNOLOGIES, LLC. shall
                not be responsible or liable, directly or indirectly, for any
                such damage or loss which may be a result of, caused or
                allegedly to be caused by or in connection with the use of or
                the reliance on any such content, goods or Services made
                available on or through any such site or resource.
            </p>

            <h2 className="container__title">PROPRIETARY RIGHTS</h2>

            <p className="container__paragraph">
                You do hereby acknowledge and agree that ALGODEVA TECHNOLOGIES,
                LLC. 's Services and any essential software that may be used in
                connection with our Services ("Software") shall contain
                proprietary and confidential material that is protected by
                applicable intellectual property rights and other laws.
                Furthermore, you herein acknowledge and agree that any Content
                which may be contained in any advertisements or information
                presented by and through our Services or by advertisers is
                protected by copyrights, trademarks, patents or other
                proprietary rights and laws. Therefore, except for that which is
                expressly permitted by applicable law or as authorized by
                ALGODEVA TECHNOLOGIES, LLC. or such applicable licensor, you
                agree not to alter, modify, lease, rent, loan, sell, distribute,
                transmit, broadcast, publicly perform and/or created any
                plagiaristic works which are based on ALGODEVA TECHNOLOGIES,
                LLC. Services (e.g. Content or Software), in whole or part.
            </p>
            <p className="container__paragraph">
                ALGODEVA TECHNOLOGIES, LLC. herein has granted you personal,
                non-transferable and non-exclusive rights and/or license to make
                use of the object code or our Software on a single computer, as
                long as you do not, and shall not, allow any third party to
                duplicate, alter, modify, create or plagiarize work from,
                reverse engineer, reverse assemble or otherwise make an attempt
                to locate or discern any source code, sell, assign, sublicense,
                grant a security interest in and/or otherwise transfer any such
                right in the Software. Furthermore, you do herein agree not to
                alter or change the Software in any manner, nature or form, and
                as such, not to use any modified versions of the Software,
                including and without limitation, for the purpose of obtaining
                unauthorized access to our Services. Lastly, you also agree not
                to access or attempt to access our Services through any means
                other than through the interface which is provided by ALGODEVA
                TECHNOLOGIES, LLC. for use in accessing our Services.
            </p>
            <h2 className="container__title">
                WARRANTY DISCLAIMERS <br /> <br /> YOU HEREIN EXPRESSLY
                ACKNOWLEDGE AND AGREE THAT:
            </h2>
            <p className="container__paragraph">
                THE USE OF ALGODEVA TECHNOLOGIES, LLC. SERVICES AND SOFTWARE ARE
                AT THE SOLE RISK BY YOU. OUR SERVICES AND SOFTWARE SHALL BE
                PROVIDED ON AN "AS IS" AND/OR "AS AVAILABLE" BASIS. ALGODEVA
                TECHNOLOGIES, LLC. AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS,
                EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ANY
                AND ALL WARRANTIES OF ANY KIND WHETHER EXPRESSED OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF TITLE,
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                NON-INFRINGEMENT.
            </p>
            <p className="container__paragraph">
                ALGODEVA TECHNOLOGIES, LLC. AND OUR SUBSIDIARIES, OFFICERS,
                EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO SUCH
                WARRANTIES THAT
            </p>
            <ul className="container__list">
                <li className="container__list-item">
                    ALGODEVA TECHNOLOGIES, LLC. SERVICES OR SOFTWARE WILL MEET
                    YOUR REQUIREMENTS
                </li>
                <li className="container__list-item">
                    ALGODEVA TECHNOLOGIES, LLC. SERVICES OR SOFTWARE SHALL BE
                    UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE
                </li>
                <li className="container__list-item">
                    THAT SUCH RESULTS WHICH MAY BE OBTAINED FROM THE USE OF THE
                    ALGODEVA TECHNOLOGIES, LLC. SERVICES OR SOFTWARE WILL BE
                    ACCURATE OR RELIABLE
                </li>
                <li className="container__list-item">
                    QUALITY OF ANY PRODUCTS, SERVICES, ANY INFORMATION OR OTHER
                    MATERIAL WHICH MAY BE PURCHASED OR OBTAINED BY YOU THROUGH
                    OUR SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS
                </li>
                <li className="container__list-item">
                    THAT ANY SUCH ERRORS CONTAINED IN THE SOFTWARE SHALL BE
                    CORRECTED
                </li>
            </ul>
            <p className="container__paragraph">
                ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED BY
                WAY OF ALGODEVA TECHNOLOGIES, LLC. SERVICES OR SOFTWARE SHALL BE
                ACCESSED BY YOUR SOLE DISCRETION AND SOLE RISK, AND AS SUCH YOU
                SHALL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL
                CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR
                COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING AND/OR DISPLAYING,
                OR FOR ANY LOSS OF DATA THAT COULD RESULT FROM THE DOWNLOAD OF
                ANY SUCH INFORMATION OR MATERIAL.
            </p>
            <p className="container__paragraph">
                NO ADVICE AND/OR INFORMATION, DESPITE WHETHER WRITTEN OR ORAL,
                THAT MAY BE OBTAINED BY YOU FROM ALGODEVA TECHNOLOGIES, LLC. OR
                BY WAY OF OR FROM OUR SERVICES OR SOFTWARE SHALL CREATE ANY
                WARRANTY NOT EXPRESSLY STATED IN THE TOS.
            </p>
            <p className="container__paragraph">
                A SMALL PERCENTAGE OF SOME USERS MAY EXPERIENCE SOME DEGREE OF
                EPILEPTIC SEIZURE WHEN EXPOSED TO CERTAIN LIGHT PATTERNS OR
                BACKGROUNDS THAT MAY BE CONTAINED ON A COMPUTER SCREEN OR WHILE
                USING OUR SERVICES. CERTAIN CONDITIONS MAY INDUCE A PREVIOUSLY
                UNKNOWN CONDITION OR UNDETECTED EPILEPTIC SYMPTOM IN USERS WHO
                HAVE SHOWN NO HISTORY OF ANY PRIOR SEIZURE OR EPILEPSY. SHOULD
                YOU, ANYONE YOU KNOW OR ANYONE IN YOUR FAMILY HAVE AN EPILEPTIC
                CONDITION, PLEASE CONSULT A PHYSICIAN IF YOU EXPERIENCE ANY OF
                THE FOLLOWING SYMPTOMS WHILE USING OUR SERVICES: DIZZINESS,
                ALTERED VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS,
                DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.
            </p>

            <h2 className="container__title">LIMITATION OF LIABILITY</h2>

            <p className="container__paragraph">
                YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT ALGODEVA
                TECHNOLOGIES, LLC. AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS,
                EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO
                YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED
                TO, DAMAGES WHICH MAY BE RELATED TO THE LOSS OF ANY PROFITS,
                GOODWILL, USE, DATA AND/OR OTHER INTANGIBLE LOSSES, EVEN THOUGH
                WE MAY HAVE BEEN ADVISED OF SUCH POSSIBILITY THAT SAID DAMAGES
                MAY OCCUR, AND RESULT FROM:
            </p>
            <p className="container__paragraph">
                THE USE OR INABILITY TO USE OUR SERVICE
            </p>
            <p className="container__paragraph">
                THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES
            </p>
            <p className="container__paragraph">
                UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR TRANSMISSIONS
                AND/OR DATA
            </p>
            <p className="container__paragraph">
                STATEMENTS OR CONDUCT OF ANY SUCH THIRD PARTY ON OUR SERVICE
            </p>
            <p className="container__paragraph">
                AND ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.
            </p>
            <p className="container__paragraph">RELEASE</p>
            <p className="container__paragraph">
                In the event you have a dispute, you agree to release ALGODEVA
                TECHNOLOGIES, LLC. (and its officers, directors, employees,
                agents, parent subsidiaries, affiliates, co-branders, partners
                and any other third parties) from claims, demands and damages
                (actual and consequential) of every kind and nature, known and
                unknown, suspected or unsuspected, disclosed and undisclosed,
                arising out of or in any way connected to such dispute.
            </p>

            <h2 className="container__title">
                SPECIAL ADMONITION RELATED TO FINANCIAL MATTERS
            </h2>

            <p className="container__paragraph">
                Should you intend to create or to join any service, receive or
                request any such news, messages, alerts or other information
                from our Services concerning companies, stock quotes,
                investments or securities, please review the above Sections
                Warranty Disclaimers and Limitations of Liability again. In
                addition, for this particular type of information, the phrase
                "Let the investor beware" is appropriate. ALGODEVA TECHNOLOGIES,
                LLC. 's content is provided primarily for informational
                purposes, and no content that shall be provided or included in
                our Services is intended for trading or investing purposes.
                ALGODEVA TECHNOLOGIES, LLC. and our licensors shall not be
                responsible or liable for the accuracy, usefulness or
                availability of any information transmitted and/or made
                available by way of our Services, and shall not be responsible
                or liable for any trading and/or investment decisions based on
                any such information.
            </p>

            <h2 className="container__title">EXCLUSION AND LIMITATIONS</h2>

            <p className="container__paragraph">
                THERE ARE SOME JURISDICTIONS WHICH DO NOT ALLOW THE EXCLUSION OF
                CERTAIN WARRANTIES OR THE LIMITATION OF EXCLUSION OF LIABILITY
                FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. THEREFORE, SOME OF THE
                ABOVE LIMITATIONS OF SECTIONS WARRANTY DISCLAIMERS AND
                LIMITATION OF LIABILITY MAY NOT APPLY TO YOU.
            </p>

            <h2 className="container__title">THIRD PARTY BENEFICIARIES</h2>

            <p className="container__paragraph">
                You herein acknowledge, understand and agree, unless otherwise
                expressly provided in this TOS, that there shall be no
                third-party beneficiaries to this agreement.
            </p>

            <h2 className="container__title">NOTICE</h2>

            <p className="container__paragraph">
                ALGODEVA TECHNOLOGIES, LLC. may furnish you with notices,
                including those with regards to any changes to the TOS,
                including but not limited to email, regular mail, MMS or SMS,
                text messaging, postings on our website Services, or other
                reasonable means currently known or any which may be herein
                after developed. Any such notices may not be received if you
                violate any aspects of the TOS by accessing our Services in an
                unauthorized manner. Your acceptance of this TOS constitutes
                your agreement that you are deemed to have received any and all
                notices that would have been delivered had you accessed our
                Services in an authorized manner.
            </p>

            <h2 className="container__title">TRADEMARK INFORMATION</h2>

            <p className="container__paragraph">
                You herein acknowledge, understand and agree that all of the
                ALGODEVA TECHNOLOGIES, LLC. trademarks, copyright, trade name,
                service marks, and other ALGODEVA TECHNOLOGIES, LLC. logos and
                any brand features, and/or product and service names are
                trademarks and as such, are and shall remain the property of
                ALGODEVA TECHNOLOGIES, LLC. . You herein agree not to display
                and/or use in any manner the ALGODEVA TECHNOLOGIES, LLC. logo or
                marks without obtaining ALGODEVA TECHNOLOGIES, LLC. 's prior
                written consent.
            </p>
            <h2 className="container__title">
                COPYRIGHT OR INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS NOTICE &
                PROCEDURES
            </h2>
            <p className="container__paragraph">
                ALGODEVA TECHNOLOGIES, LLC. will always respect the intellectual
                property of others, and we ask that all of our users do the
                same. With regards to appropriate circumstances and at its sole
                discretion, ALGODEVA TECHNOLOGIES, LLC. may disable and/or
                terminate the accounts of any user who violates our TOS and/or
                infringes the rights of others. If you feel that your work has
                been duplicated in such a way that would constitute copyright
                infringement, or if you believe your intellectual property
                rights have been otherwise violated, you should provide to us
                the following information:
            </p>
            <p className="container__paragraph">
                The electronic or the physical signature of the individual that
                is authorized on behalf of the owner of the copyright or other
                intellectual property interest
            </p>
            <p className="container__paragraph">
                A description of the copyrighted work or other intellectual
                property that you believe has been infringed upon
            </p>
            <p className="container__paragraph">
                A description of the location of the site which you allege has
                been infringing upon your work
            </p>
            <p className="container__paragraph">
                Your physical address, telephone number, and email address
            </p>
            <p className="container__paragraph">
                A statement, in which you state that the alleged and disputed
                use of your work is not authorized by the copyright owner, its
                agents or the law
            </p>
            <p className="container__paragraph">
                And finally, a statement, made under penalty of perjury, that
                the aforementioned information in your notice is truthful and
                accurate, and that you are the copyright or intellectual
                property owner, representative or agent authorized to act on the
                copyright or intellectual property owner's behalf.
            </p>
            <p className="container__paragraph">CLOSED CAPTIONING</p>
            <p className="container__paragraph">GENERAL INFORMATION</p>
            <p className="container__paragraph">ENTIRE AGREEMENT</p>
            <p className="container__paragraph">
                This TOS constitutes the entire agreement between you and
                ALGODEVA TECHNOLOGIES, LLC. and shall govern the use of our
                Services, superseding any prior version of this TOS between you
                and us with respect to ALGODEVA TECHNOLOGIES, LLC. Services. You
                may also be subject to additional terms and conditions that may
                apply when you use or purchase certain other ALGODEVA
                TECHNOLOGIES, LLC. Services, affiliate Services, third-party
                content or third-party software.
            </p>

            <h2 className="container__title">CHOICE OF LAW AND FORUM</h2>

            <p className="container__paragraph">
                It is at the mutual agreement of both you and ALGODEVA
                TECHNOLOGIES, LLC. with regard to the TOS that the relationship
                between the parties shall be governed by the laws of United Arab
                Emirates without regard to its conflict of law provisions and
                that any and all claims, causes of action and/or disputes,
                arising out of or relating to the TOS, or the relationship
                between you and ALGODEVA TECHNOLOGIES, LLC. , shall be filed
                within the courts having jurisdiction within United Arab
                Emirates. You and ALGODEVA TECHNOLOGIES, LLC. agree to submit to
                the jurisdiction of the courts as previously mentioned, and
                agree to waive any and all objections to the exercise of
                jurisdiction over the parties by such courts and to venue in
                such courts.
            </p>

            <h2 className="container__title">
                WAIVER AND SEVERABILITY OF TERMS
            </h2>

            <p className="container__paragraph">
                At any time, should ALGODEVA TECHNOLOGIES, LLC. fail to exercise
                or enforce any right or provision of the TOS, such failure shall
                not constitute a waiver of such right or provision. If any
                provision of this TOS is found by a court of competent
                jurisdiction to be invalid, the parties nevertheless agree that
                the court should endeavor to give effect to the parties'
                intentions as reflected in the provision, and the other
                provisions of the TOS remain in full force and effect.
            </p>

            <h2 className="container__title">STATUTE OF LIMITATIONS</h2>

            <p className="container__paragraph">
                You acknowledge, understand and agree that regardless of any
                statute or law to the contrary, any claim or action arising out
                of or related to the use of our Services or the TOS must be
                filed within 1 year(s) after said claim or cause of action arose
                or shall be forever barred.
            </p>
        </div>
    );
};

export default TermsPage;
