import { useEffect } from "react";
import { Helmet } from "react-helmet";
const RefundPage = ({ title }) => {
    // useEffect(() => {
    //   document.title = title;
    // });
    return (
        <div className="container">
            <Helmet>
                <title>Algodeva- Refund policy</title>

                <meta property="og:title" content="Algodeva- Refund policy" />
            </Helmet>
            <h1 className="container__heading">Our Refund Policy</h1>
            <h2 className="container__title">Coverage & Scope</h2>
            <p className="container__paragraph">
                This Refund Policy covers ALGODEVA TECHNOLOGIES’s action and
                approach towards refunds. This Refund Policy does not apply to
                the practices of companies that ALGODEVA TECHNOLOGIES does not
                own or control or of persons that ALGODEVA TECHNOLOGIES does not
                employ or manage, including any third-party service and/or
                product providers bound by contract and any third-party websites
                to which ALGODEVA TECHNOLOGIES’s websites link.
            </p>
            <h2 className="container__title">
                When Do You Qualify For Refunds?
            </h2>
            <p className="container__paragraph">
                This Refund Policy covers ALGODEVA TECHNOLOGIES’s action and
                approach towards refunds. This Refund Policy does not apply to
                the practices of companies that ALGODEVA TECHNOLOGIES does not
                own or control or of persons that ALGODEVA TECHNOLOGIES does not
                employ or manage, including any third-party service and/or
                product providers bound by contract and any third-party websites
                to which ALGODEVA TECHNOLOGIES’s websites link.
            </p>
            <p className="container__paragraph">
                When you file a complaint to ALGODEVA TECHNOLOGIES, we first try
                to provide an ideal solution to the issue you are facing. Your
                case is first introduced to the technical team and then reported
                further to the experts’ panel. Refund is only made in the
                extreme circumstances, when nothing good can be done to solve
                the issue.
            </p>
            <p className="container__paragraph">
                While planning for refunds we check for soundness and validity
                of the case, applying different conditions to it. If these
                conditions are satisfied, refund may apply*
            </p>
            <ul className="container__list">
                <li className="container__list-item">
                    Development/Post-sales operations has not yet started
                </li>
                <li className="container__list-item">
                    You have not violated our payment terms
                </li>
                <li className="container__list-item">
                    You have not used any information for monetary/business
                    benefits (gained during the course of association)
                </li>
                <li className="container__list-item">
                    Should be able to demonstrate his/her innovative UI design
                    and development skills and understand what makes an app
                    addictive and appealing
                </li>
                <li className="container__list-item">
                    You have presented all the proofs and evidences surfacing
                    your refund claim
                </li>
                <li className="container__list-item">
                    The claim does not arise from any billing dispute coming
                    from your bank or payment vendor
                </li>
                <li className="container__list-item">
                    Important Note: If any of the points mentioned above is
                    found violated, your claim will be considered void. The
                    decision on refunds is final and irrevocable.
                </li>
            </ul>
            <h2 className="container__title">How The Refund Takes Place?</h2>
            <h3 className="container__list-title">1. Pre-Refund</h3>
            <ul className="container__list">
                <li className="container__list-item">
                    Refund query is processed, analyzed and checked for veracity
                </li>
                <li className="container__list-item">
                    Match the query with the agreeable parameters and valid
                    reference points
                </li>
                <li className="container__list-item">
                    Arrange for refunds in permissible situations and settle
                    fully or partly, as the case may be
                </li>
            </ul>

            <h3 className="container__list-title">2. Post-Refund</h3>
            <ul className="container__list">
                <li className="container__list-item">Sign a proof agreement</li>
                <li className="container__list-item">
                    Unless you have been told otherwise, you destroy all copies
                    of information shared and communication that happened in the
                    due course
                </li>
            </ul>

            <h3 className="container__list-title">3. Transaction</h3>
            <ul className="container__list">
                <li className="container__list-item">
                    The refund may take 7-10 business days to process, after the
                    refund agreement is signed
                </li>
                <li className="container__list-item">
                    You will be refunded in the currency you were charged in. If
                    this is not your native currency, your bank may charge
                    exchange fees, or a change in the exchange rate may have
                    resulted in a difference in the amount refunded compared to
                    the amount you originally paid (in your native currency). It
                    is solely your responsibility if you have to pay any fees or
                    bear any losses in this process.
                </li>
            </ul>
        </div>
    );
};

export default RefundPage;
