import searchIcon from "../../../assets/images/search.png";
import settingsIcon from "../../../assets/images/settings.png";
import statisticsIcon from "../../../assets/images/statistics.png";
import blob from "../../../assets/images/blob2.png";
import Service from "./Service";
import Pulse from "react-reveal/Pulse";

const FeaturesSection = () => {
  const services = [
    {
      icon: searchIcon,
      title: "Apps Development",
      paragraph: "Web , Mobile",
    },
    {
      icon: settingsIcon,
      title: "Cloud",
      paragraph: "Azure , GCP , AWS",
    },
    {
      icon: statisticsIcon,
      title: "Analytics and engineering",
      paragraph: "Data analysis , Machine learning , AI",
    },
  ];

  return (
    <div id="features">
      <div className="features">
        <Pulse>
          <span className="features__word">FEATURES</span>
          <h2 className="features__title">Services We Provide</h2>
        </Pulse>
        <div className="features__content">
          <div className="features__services">
            {services.map((service) => (
              <Service
                icon={service.icon}
                title={service.title}
                paragraph={service.paragraph}
              />
            ))}
          </div>
          <div className="features__blob">
            <img src={blob} alt="blob" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
