import workflowIcon from "../../../assets/images/workflow.svg";
import researchIcon from "../../../assets/images/research.png";
import idetateIcon from "../../../assets/images/idetate.png";
import designIcon from "../../../assets/images/design.png";
import developmentIcon from "../../../assets/images/development.png";
import testingIcon from "../../../assets/images/testing.png";
import Reveal from "react-reveal/Reveal";

const workflows = [
    {
        icon: researchIcon,
        title: "Research",
        paragraph:
            " One of the most important steps in our work flow is research, We need to research widely to build background knowledge and begin narrowing down approaches, We make sure to consider the practicalities: the requirements of the project, the amount of time it would take to complete the project, and how difficult it will be to access resources and data on the project.",
    },
    {
        icon: idetateIcon,
        title: "Ideate",
        paragraph:
            " Ideation is the mode of the design process in which we concentrate on idea generation. Mentally it represents a process of -going wide- in terms of concepts and outcomes. Ideation provides both the fuel and also the source material for building prototypes and getting innovative solutions into the hands of the clients.",
    },
    {
        icon: designIcon,
        title: "Design",
        paragraph:
            " The design stage is where the UI (user interface) and UX (user experience) design of the project is conceived and iterated upon until we have a final blueprint for the developers to then build.",
    },
    {
        icon: developmentIcon,
        title: "Development",
        paragraph:
            " In the development stage we build the app this is done in what are known as development sprints. This is part of the Agile methodology, and breaks development up into periods of around two weeks apiece, each focused on a particular bit of functionality.Once all these development sprints are complete - typically, there might be seven of them in a project - you have what is known as a UAT (User Acceptance Testing) version, for the client to test and sign off on.",
    },
    {
        icon: testingIcon,
        title: "Testing",
        paragraph:
            " The client test the UAT (User Acceptance Testing) version and and we fix any reported bugs,At that point, it moves to RC (Release Candidate) status, where all necessary refinements are made so it can be pushed out on the app stores, or the client's website, or whatever release looks like for the particular project.",
    },
];

const WorkflowSection = () => {
    return (
        <Reveal>
            <div className="workflow">
                <img src={workflowIcon} alt="workflow-icon" />
                <h5 className="workflow__main-title">Our Work Process</h5>
                <p className="workflow__paragraph">
                    All our Projects are cloud based, serverless or whatever
                    suits the client needs.
                </p>

                <div class="workflow__timeline">
                    <div class="workflow__outer">
                        {workflows.map((workflow) => (
                            <div class="workflow__card">
                                <div class="workflow__info">
                                    <div
                                        className="workflow__title-image-container"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src={workflow.icon}
                                            className="workflow__card-image"
                                            alt="card-icon"
                                        />
                                        <h3 class="workflow__title">
                                            {workflow.title}
                                        </h3>
                                    </div>
                                    <p className="workflow__card-paragraph">
                                        {workflow.paragraph}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Reveal>
    );
};

export default WorkflowSection;
