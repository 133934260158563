import { Link } from "react-router-dom";

const List = ({ list }) => {
    console.log();
    return (
        <div className={`${list.links[0].icon ? "list list--icons" : "list"}`}>
            <h6 className="list__title">{list.title}</h6>
            <ul>
                {list.links.map((link) => (
                    <>
                        {link.title ? (
                            <li className="list__item">
                                <Link className="list__link" to={link.url}>
                                    {link.title}
                                </Link>
                            </li>
                        ) : (
                            <li className="list__icon">
                                <a href={link.url}>
                                    <img
                                        src={link.icon}
                                        alt="socialmedia-icon"
                                    />
                                </a>
                            </li>
                        )}
                    </>
                ))}
            </ul>
        </div>
    );
};

export default List;
