import FeaturesSection from "../components/HomePage/FeaturesSection/FeaturesSection";
import HeroSection from "../components/HomePage/HeroSection/HeroSection";
import ContactSection from "../components/Shared/Contact/ContactSection";
import WorkflowSection from "../components/Shared/Workflow/WorkflowSection";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
const HomePage = ({ title }) => {
    return (
        <>
            <HeroSection />
            <FeaturesSection />
            <WorkflowSection />
            <ContactSection />
        </>
    );
};

export default HomePage;
