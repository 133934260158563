// @ts-nocheck
import axios from "axios";

const BASE_URL = `https://44.202.101.189/api`;


export const subscribe = async (data) => {
    const response = await axios.post(`${BASE_URL}/utility/subscribe` , data);
    return response.data;
};


export const contact = async (data) => {
    const response = await axios.post(`${BASE_URL}/utility/contact-us` , data);
    return response.data;
};