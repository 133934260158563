import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Layout/Header";
import HomePage from "./pages/HomePage";
import Footer from "./components/Layout/Footer";
import AboutPage from "./pages/AboutPage";
import RefundPage from "./pages/RefundPage";
import TermsPage from "./pages/TermsPage";
import ContactPage from "./pages/ContactPage";
import {
  
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()

function App() {
    
  
  return (
        <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage title="Algodeva - Take your business to the next level"/>} />
        <Route path="/about" element={<AboutPage title="Algodeva - About us" />} />
        <Route path="/refund-policy" element={<RefundPage title="Algodeva - Refund policy" />} />
        <Route path="/terms-of-service" element={<TermsPage title="Algodeva - Terms of service" />} />
        <Route path="/contact-us" element={<ContactPage title="Algodeva - Contact us" />} />
      </Routes>
      <Footer />
    </BrowserRouter></QueryClientProvider>
  );
}

export default App;
