import Particles from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import devices from "../../../assets/images/devices.svg";
import { Slide } from "react-reveal";

const HeroSection = () => {
    const particlesInit = useCallback(async (engine) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {}, []);

    return (
        <div className="hero">
            {window.innerWidth >= 768 && (
                <Particles
                    className="hero__particles"
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        background: {
                            color: {
                                value: "#fffff",
                            },
                        },
                        fpsLimit: 120,
                        preset: "bubbles",
                        particles: {
                            color: {
                                value: "#4981da",
                            },
                            move: {
                                directions: "top",
                                enable: true,
                                random: true,
                                speed: 2,
                                straight: true,
                                out_mode: "out",
                            },
                            number: {
                                value: 3,
                                density: {
                                    enable: true,
                                    area: 631,
                                },
                            },
                            opacity: {
                                value: 0.45,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                value: 20,
                            },
                        },
                        detectRetina: true,
                    }}
                />
            )}
            <div className="hero__content">
                <Slide left>
                    <div>
                        <h1 className="hero__title">
                            We Are Algodeva{" "}
                            <span className="hero__title hero__title--blue">
                                Software
                            </span>{" "}
                            House
                        </h1>
                        <p className="hero__paragraph">
                            ALGODEVA is a software solutions provider working on
                            different stacks to guarantee high quality services
                            and massive coverage for the market needs.
                        </p>
                    </div>
                </Slide>
                <Slide right>
                    <div className="hero__devices">
                        <img src={devices} alt="devices" />
                    </div>
                </Slide>
            </div>
        </div>
    );
};

export default HeroSection;
