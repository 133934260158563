import logo from "../../assets/images/logo.svg";
import List from "../List/List";
import facebookIcon from "../../assets/images/facebook.svg";
import githubIcon from "../../assets/images/github.svg";
import twitterIcon from "../../assets/images/twitter.svg";
import Reveal from "react-reveal/Reveal";
const footerLists = [
  {
    title: "Company",
    links: [
      {
        title: "About",
        url: "/about",
      },
    ],
  },
  // {
  //     title: "Discover",
  //     links: [
  //         {
  //             title: "Our Blog",
  //             url: "#",
  //         },
  //         {
  //             title: "Advertising",
  //             url: "#",
  //         },
  //         {
  //             title: "Plans&Prices",
  //             url: "#",
  //         },
  //         {
  //             title: "Testimonials",
  //             url: "#",
  //         },
  //     ],
  // },
  {
    title: "Legal",
    links: [
      {
        title: "Refund policy",
        url: "/refund-policy",
      },
      {
        title: "Terms of service",
        url: "/terms-of-service",
      },
    ],
  },
  {
    title: "Contact With Us",
    links: [
      // {
      //   icon: facebookIcon,
      //   url: "#",
      // },
      {
        icon: githubIcon,
        url: "#",
      },
      // {
      //   icon: twitterIcon,
      //   url: "#",
      // },
    ],
  },
];

const Footer = () => {
  return (
    <Reveal>
      <footer className="footer">
        <div className="footer__content">
          <img className="footer__logo" src={logo} alt="logo" />

          {footerLists.map((list, index) => (
            <List key={index} list={list} />
          ))}
        </div>
      </footer>
    </Reveal>
  );
};

export default Footer;
